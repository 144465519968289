@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.headerWrapperSticky,
.headerWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  z-index: 100;
  right: 0;
  @include fwcontainer;
}

.headerWrapperSticky {
  position: fixed;
}

.header_wrapper {
  height: 6.6rem;
  display: grid;
  grid-template-columns: 60px 287px 1fr max-content;
  font-family: Avenir, sans-serif;
  place-items: center;
  border-bottom: 1px solid $grey_3;
  align-items: center;
  font-family: 'Avenir';
  background-color: #ffffff;
  max-width: 1280px;
  width: 100%;
  margin-top: 2rem;
  gap: 15px;

  @include respond(tablet) {
    padding: 0 1rem;
    grid-column-gap: 1rem;
  }

  @include respond(mobile) {
    display: flex;
    margin: auto;
  }

  .logo_wrapper {
    justify-self: flex-start;
    cursor: pointer;

    a {
      display: inline-block;
      width: 60px;
    }
  }

  .explore_exams {
    @include flex(row, center, flex-start);
    span,
    img {
      cursor: pointer;
    }
    .explore_exam_title {
      font-size: $normal-big;
      color: $dark_4;
    }
    @include respond(mobile) {
      display: none;
    }
  }

  .search_wrapper {
    position: relative;
    border: 1px solid #bfbefc;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    @include flex(row, center, flex-start);
    padding: 1.3rem 1.9rem 1.3rem 1rem;
    flex-wrap: nowrap;
    gap: 11px;
    cursor: text;

    input {
      width: 100%;
      font-size: $normal;

      &::placeholder {
        font-size: $normal;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    @include respond(mobile) {
      width: 100%;
      border-radius: 1rem;
      padding: 1.2rem 1.5rem;
      input {
        margin-left: 1rem;
      }
    }
    .searchresults_wrapper {
      position: absolute;
      top: 5.5rem;
      background: white;
      left: 0;
      width: 100%;
      height: 25rem;
      overflow-y: auto;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 0.5rem;
      .search_result {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        padding: 0.75rem;
        font-size: $normal;
        cursor: pointer;
        color: $blue_1;
        font-weight: 500;
      }
      .no_courses_found {
        height: 100%;
        @include flex(row, center, center);
        font-weight: 500;
        font-size: $normal;
      }
    }
  }
  .search_wrapper:focus-within {
    border: 1px solid #423dcf;
  }

  .hmenu {
    display: none;
    @include respond(mobile) {
      display: block;
      margin: 0 auto;
    }
  }
}

// explore courses section
.categories_wrapper {
  @include respond(mobile) {
    display: none;
  }
  .catgories_wrapper_visible {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
  }
  .categories_wrapper_hidden {
    @extend .catgories_wrapper_visible;
    opacity: 0;
  }

  .category_content_wrapper {
    background: #ffffff;
    box-shadow: 0px 0.4rem 2rem rgba(0, 0, 0, 0.1);
    height: max-content;
    width: max-content;
    margin-left: 21rem;
    border-radius: 0.75rem;
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 15.8rem);
    gap: 1.8rem;
    @include respond(tablet) {
      grid-template-columns: repeat(4, 15rem);
      margin: 0 auto;
      gap: 1rem;
    }

    .category {
      border-radius: 0.4rem;
      border: 1px solid rgba(0, 0, 0, 0.08);
      height: 8rem;
      @include flex(column, center, center);
      gap: 0.8rem;
      font-family: 'Avenir';
      font-size: $small;
      font-weight: 400;
      flex-wrap: nowrap;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        border: 1px solid $primary;
      }
    }
  }
}

.exploreStore, .exploreOfflineStore, .facultyEnhancement {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px 14px;
  cursor: pointer;
  margin-left: auto;
  text-decoration: none;

  @include respond(tablet) {
    display: none;
  }

  span {
    color: #423dcf;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
}

.facultyEnhancement {
  
}

.exploreOfflineStore {
  &::before {
    content: 'Checkout our New';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #067306;
    background-color: #eaf9de;
    padding: 3px 8px;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 4px;
    white-space: nowrap;
  }
}
